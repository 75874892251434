// @ts-check

/* eslint-disable no-restricted-globals */

/**
 * Takes a duration in seconds and formats it as a string
 * HH:MM:SS
 *
 * @param {string} duration
 * @returns {string}
 */
const formatDurationPlaceholder = duration => {
  const secNum = parseInt(duration, 10);

  if (isNaN(secNum)) return '0:00';

  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - hours * 3600) / 60);
  const seconds = secNum - hours * 3600 - minutes * 60;
  let time = '';

  if (hours) {
    time = `${hours} hr ${minutes} min`;
  } else if (minutes) {
    time = `${minutes}:${seconds} min`;
  } else {
    time = `${seconds} sec`;
  }

  return time;
};

export default formatDurationPlaceholder;
